import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/(public)/accountDeletion": [31,[5]],
		"/(protected)/auth/forgottenPassword": [7,[2,3]],
		"/(protected)/auth/login": [8,[2,3]],
		"/(protected)/auth/organizationLinkJoin": [9,[2,3]],
		"/(protected)/auth/passwordReset": [10,[2,3]],
		"/(protected)/auth/signUpInfo": [12,[2,3]],
		"/(protected)/auth/signUp": [11,[2,3]],
		"/(public)/notFound": [32,[5]],
		"/(public)/playerStatistics": [33,[5]],
		"/(protected)/portal": [13,[2,4]],
		"/(protected)/portal/guide": [14,[2,4]],
		"/(protected)/portal/matchVenues": [15,[2,4]],
		"/(protected)/portal/matches": [16,[2,4]],
		"/(protected)/portal/matches/inviteResponsePreview": [17,[2,4]],
		"/(protected)/portal/matches/join": [18,[2,4]],
		"/(protected)/portal/matches/roster": [19,[2,4]],
		"/(protected)/portal/matches/statistics": [20,[2,4]],
		"/(protected)/portal/organizations": [21,[2,4]],
		"/(protected)/portal/organizations/join": [22,[2,4]],
		"/(protected)/portal/organizations/profile": [23,[2,4]],
		"/(protected)/portal/players": [24,[2,4]],
		"/(protected)/portal/players/profile": [25,[2,4]],
		"/(protected)/portal/pricing": [26,[2,4]],
		"/(protected)/portal/pricing/success": [27,[2,4]],
		"/(protected)/portal/profile": [28,[2,4]],
		"/(protected)/portal/profile/myStatistics": [29,[2,4]],
		"/(protected)/portal/teams": [30,[2,4]],
		"/(public)/privacyPolicy": [34,[5]],
		"/(public)/viber": [35,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';